import React, { useState, useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { motion } from "framer-motion";
import spinnerDefault from '../../assets/img/common/spinner/Spinner default.png';
import spinner2 from '../../assets/img/common/spinner/Spinner Variant 2.png';
import spinner3 from '../../assets/img/common/spinner/Spinner Variant 3.png';
import spinner4 from '../../assets/img/common/spinner/Spinner Variant 4.png';

export default function LoadingSpinner() {
  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    const images = [spinnerDefault, spinner2, spinner3, spinner4];
    const interval = setInterval(() => {
      setImageIndex((prevIndex) => (prevIndex === 3 ? 0 : prevIndex + 1));
    }, 900);

    return () => clearInterval(interval);
  }, []);

  const images = [spinnerDefault, spinner2, spinner3, spinner4];

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      width="100vw"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      zIndex="9999"
      bg="rgba(0,0,0,0.5)"
      backdropFilter="blur(5px)"
      pointerEvents="none"
    >
      <motion.img
        key={images[imageIndex]}
        src={images[imageIndex]}
        alt="Loading spinner"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      />
    </Box>
  );
}
