import React, { useState } from "react";
import { HStack, VStack } from "@chakra-ui/react";

import fondo from "../../assets/svg/wave2.svg";
import { useNavigate } from "react-router-dom";

const Menu = () => {
  const [selectedImage, setSelectedImage] = useState(null); //es null
  const navigate = useNavigate();

  const toNegatoscopio = () => {
    navigate("/negatoscope");
  };
  //  { state: { image: selectedImage } }

  const toInicio = () => {
    navigate("/");
  };

  return (
    <VStack
      background={`url(${fondo})`}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      h="100%"
      justify="space-between"
      w="100%"
    >
      <div>
        <h1 style={{ textAlign: "center" }}>SELECCIONE PANTOMOGRAFÍA</h1>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <input
            type="file"
            accept=".jpg, .png, .jpeg|image/*"
            name="myImage"
            onChange={(event) => {
              setSelectedImage(event.target.files[0]);
            }}
          />
        </div>

        <br />

        {/* {selectedImage && ( */}
        <div>
          <img
            alt="not fount"
            height={"512px"}
            // src={URL.createObjectURL(selectedImage)}
          />
          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
              marginTop: "2%",
            }}
          >
            <button
              style={{ borderColor: "#59C4FF", border: "1px solid #3992FF" }}
              type="button"
              onClick={() => toNegatoscopio()}
            >
              Siguiente
            </button>
          </div>
        </div>
        {/* )} */}
      </div>
      <HStack
        paddingBottom="20px"
        paddingLeft="50px"
        paddingTop="40px"
        w="100%"
      >
        <button
          style={{ borderColor: "#59C4FF", border: "1px solid #3992FF" }}
          onClick={() => toInicio()}
          type="button"
        >
          Inicio
        </button>
      </HStack>
    </VStack>
  );
};

export default Menu;
