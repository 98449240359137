import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import "./index.css";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { I18nextProvider } from "react-i18next";
import i18n from "./locales/i18next.js";

import ErrorPage from "./components/Error/ErrorPage.jsx";
import Home from "./pages/Home/Home.jsx";
import MyNegatoscopio from "./pages/ImagingViewer/ImagingViewer.jsx";
import Menu from "./pages/Old/menu.jsx";
import ConfigAxios from "./service/axiosConfig.js";
import Procesada from "./pages/Old/Procesada.jsx";
import { ImageProvider } from "./components/ImageContext/ImageProvider.jsx";

const theme = extendTheme({
  fonts: {
    primaryFont: "Spectral",
    secondaryFont: "Prompt Light, sans-serif  ",
  },
  colors: {
    mainColor: "rgba(0, 0, 0, 0.8)",
    mainLight: "rgba(0, 0, 0, 0.36)",
    primaryColor: "rgba(3, 36, 155, 1)",
    secondaryColor: "rgba(177, 245, 192, 1)",
    headingAzul: "rgba(3, 36, 155, 1)",
    azulClaro: "rgba(212, 225, 229, 1)",
    blancoIntermedio: "rgba(242, 247, 249, 1)",
    azulClaroBackground: "rgba(184, 209, 240, 1)",
    azulOscuro: "rgba(66, 118, 180, 0.7)",
    grisFooter: "rgba(242, 247, 249, 1)",
    languageDropDown: "rgba(242, 247, 249, 1)",
  },
  styles: {
    global: {
      body: {
        bg: "rgba(255, 255, 255)",
      },
    },
  },
});
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/selectImage",
    element: <Menu />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/negatoscope",
    element: <MyNegatoscopio />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/process",
    element: <Procesada />,
    errorElement: <ErrorPage />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ImageProvider>
      <I18nextProvider i18n={i18n}>
        <ChakraProvider theme={theme}>
          <ConfigAxios />
          <RouterProvider router={router} />
        </ChakraProvider>
      </I18nextProvider>
    </ImageProvider>
  </React.StrictMode>
);
