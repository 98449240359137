import axios from "axios";

const getImagenes = async () => {
  let response = await axios.get(
    "https://dientes-sepulveda.herokuapp.com/pantomografias"
  );
  return response.data;
};

const postProcessImage = async (image) => {
  const formData = new FormData();
  formData.append("image", image, image.name);

  let response = await axios.post(
    "https://panto.jmbajo.com/process",
    formData,
    {
      headers: {
        "Content-Type": `multipart/form-data`,
      },
    }
  );
  return response;
};

const getImageProcessed = async (id_image) => {
  let response = await axios.get(
    `https://panto.jmbajo.com/getimage/${id_image}`,
    {
      responseType: "arraybuffer",
    }
  );
  return response.data;
};

export default {
  getImagenes,
  postProcessImage,
  getImageProcessed,
};
