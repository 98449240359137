import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import Flag from 'react-world-flags';

function LanguageDropDown() {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(() => {
    const savedLanguage = localStorage.getItem("language");
    return savedLanguage ? JSON.parse(savedLanguage) : { label: "Es", code: "ES" };
  });

  const changeLanguage = (lng, label, code) => {
    i18n.changeLanguage(lng);
    const newLanguage = { label, code };
    setLanguage(newLanguage);
    // Guardar el idioma en localStorage
    localStorage.setItem("language", JSON.stringify(newLanguage));
  };

  return (
    <Box position="fixed" top="4"  right="4" fontSize="16" zIndex="9999">
      <Menu>
        <MenuButton
          as={Button}
          borderRadius={8}
          boxShadow={"0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)"}
          background={"languageDropDown"}
          rightIcon={<ChevronDownIcon />}
          aria-label="Options"
        >
          <HStack spacing="4">
            <Flag code={language.code} style={{ width: '20px', height: '20px' }} />
            <Text>{language.label}</Text>
          </HStack>
        </MenuButton>
        <MenuList>
          <MenuItem onClick={() => changeLanguage("es", "Es", "ES")}>
            <HStack spacing="4">
              <Flag code="ES" style={{ width: '20px', height: '20px' }} />
              <Text>Es</Text>
            </HStack>
          </MenuItem>
          <MenuItem onClick={() => changeLanguage("en", "En", "GB")}>
            <HStack spacing="4">
              <Flag code="GB" style={{ width: '20px', height: '20px' }} />
              <Text>En</Text>
            </HStack>
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
}

export default LanguageDropDown;
