import { Box, Image, Text, Link } from "@chakra-ui/react";
import { linkPagina, linkMail } from './Info/InfoFooter';
import logoFooter from '../../assets/img/common/footer/LogoFooter.png';
import celular from '../../assets/svg/mobile.svg';
import mail from '../../assets/svg/tabler_mail.svg';
import linkPag from '../../assets/svg/link.svg';

function Footer() {
  return (
    <Box
      as="footer"
      bg={"grisFooter"}
      width="100%"
      borderTopRadius={"16px"}
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"space-around"}
      pb={"20px"}
      pt={"10px"}
      px={"12px"}
      fontFamily="secondaryFont"
      fontWeight={400}
      fontSize={"24px"}
      h={"10vh"}
    >
      <Box>
        <Image src={logoFooter} alt="panto" borderRadius="md"/>
      </Box>

      <Box mt={"20px"}>
        <Box display={"flex"} flexDirection={"row"}>
          <Image src={linkPag} alt="panto" borderRadius="md" pr={"16px"} />
          <Link href={linkPagina} isExternal textDecoration="none" _hover={{textDecoration: "none"}}>
            <Text>www.diagnosticodental.com.ar</Text>
          </Link>
        </Box>
      </Box>

      <Box mt={"20px"}>
        <Box display={"flex"} flexDirection={"row"}>
          <Image src={mail} alt="panto" borderRadius="md" pr={"16px"} />
          <Link href={`mailto:${linkMail}`} textDecoration="none" _hover={{textDecoration: "none"}}>
            <Text>{linkMail}</Text>
          </Link>
        </Box>
      </Box>

      <Box mt={"20px"}>
        <Box display={"flex"} flexDirection={"row"}>
          <Image src={celular} alt="panto" borderRadius="md" pr={"16px"} />
          <Text>+54 291 442 8645</Text>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
