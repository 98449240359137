import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useImageContext } from "../ImageContext/ImageProvider";
import { useTranslation } from "react-i18next";
import {
  Box,
  Heading,
  Button,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
} from "@chakra-ui/react";
import dowloadSvg from "../../assets/svg/dowload.svg";
import back from "../../assets/svg/back.svg";
import lupa from "../../assets/svg/lupa.svg";

import useProcessImage from "../../service/useProcessImage";
import LoadingSpinner from "../../components/Spinner/LoadingSpinner";

function ModalImage({ isOpen, onClose }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { processImage } = useProcessImage();
  const hiddenFileInput = React.useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const {selectedImage, setSelectedImage, clearSelectedImage} = useImageContext();
  const [loading, setLoading] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleChangeFile = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    // console.log(file);
    setSelectedImage([
      ...selectedImage,
      URL.createObjectURL(event.target.files[0]),
    ]);
    setIsImageLoaded(false);
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const clearSelectedInputImg = () => {
    clearSelectedImage()
    setSelectedFile(null);
  };

  const handleAnalyzeClick = async () => {
    setLoading(true);
    await processImage(selectedFile);
    navigate("/negatoscope");
    setLoading(false);
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      {loading && <LoadingSpinner />}
      {selectedImage.length === 0 ? (
        <ModalContent
          borderRadius={"100px"}
          maxH={"448px"}
          maxW={"874px"}
          p={"30px"}
          fontFamily="secondaryFont"
          fontSize={"24px"}
        >
          <ModalHeader
            display="flex"
            justifyContent="center"
            mt={"10px"}
            fontWeight={"400"}
            fontSize={"24px"}
            mb={"10px"}
          >
            <Box
              width="600px"
              height="240px"
              display={"flex"}
              flexDirection="column"
              padding="20px"
              backgroundColor="grisFooter"
              borderRadius="10px"
            >
              <Box>
                <Box
                  m={"20px"}
                  ml={"50px"}
                  display={"flex"}
                  justifyContent={"center"}
                >
                  <Button
                    onClick={handleClick}
                    variant="outline"
                    background="azulClaroBackground"
                    color="black"
                    border="none"
                    height="48px"
                    width="260px"
                    borderRadius="30px"
                    fontSize="24px"
                    boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.10)"
                    _hover={{ backgroundColor: "none" }}
                  >
                    <Image
                      boxSize="24px"
                      m="10px"
                      src={dowloadSvg}
                      alt="dowloadIcon"
                    />
                    {t("Header.input_button")}
                  </Button>
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    style={{ display: "none" }}
                    onChange={handleChangeFile}
                    accept=".jpg, .png, .jpeg, image/"
                  />
                </Box>
                <Box display={"flex"} flexDirection={"row"}>
                  <Heading
                    textAlign="center"
                    fontWeight="400"
                    p={"36px"}
                    fontSize="x-large"
                    fontFamily="secondaryFont"
                  >
                    <span style={{ fontWeight: 600 }}> {t("Header.input_text")}</span> {t("Header.input_text2")}{" "}
                    <span style={{ fontWeight: 600 }}>{t("Header.input_text3")}</span> {t("Header.input_text4")}
                  </Heading>
                </Box>
              </Box>
            </Box>
          </ModalHeader>
          <ModalFooter display="flex" justifyContent={"center"}>
            <Button
              background="azulClaroBackground"
              color="black"
              height="48px"
              width="260px"
              borderRadius="30px"
              fontSize={"24px"}
              onClick={onClose}
              _hover={{ backgroundColor: "none" }}
              gap={2}
            >
              <Image boxSize="24px" m="10px" src={back} alt="backIcon" />
              {t("Header.button_back")}
            </Button>
            <Button
              m={"10px"}
              background="secondaryColor"
              color="black"
              height="48px"
              width="260px"
              borderRadius="30px"
              fontSize={"24px"}
              _hover={{ backgroundColor: "none" }}
            >
              {t("Header.button_analize")}
              <Image boxSize="24px" m="10px" src={lupa} alt="lupaIcon" />
            </Button>
          </ModalFooter>
        </ModalContent>
      ) : (
        <ModalContent
          borderRadius={"100px"}
          maxH={"448px"}
          maxW={"874px"}
          p={"30px"}
          fontFamily="secondaryFont"
          fontSize={"24px"}
          display={"flex"}
          alignItems={"center"}
        >
          <Image
            borderRadius={9}
            height={"230px"}
            width={"480px"}
            src={selectedImage[0]}
            onLoad={handleImageLoad}
            alt="Imagen cargada"
          />
          <ModalFooter display="flex" justifyContent={"center"}>
            <Button
              m={"10px"}
              background="azulClaroBackground"
              color="black"
              height="48px"
              width="260px"
              borderRadius="30px"
              fontSize={"24px"}
              onClick={() => {
                onClose();
                clearSelectedInputImg ();
              }}
              _hover={{ backgroundColor: "none" }}
            >
              <Image boxSize="24px" m="10px" src={back} alt="backIcon" />
              {t("Header.button_back")}
            </Button>
            <Button
              m={"10px"}
              background="secondaryColor"
              color="black"
              height="48px"
              width="260px"
              borderRadius="30px"
              fontSize={"24px"}
              onClick={handleAnalyzeClick}
              _hover={{ backgroundColor: "none" }}
            >
              {t("Header.button_analize")}
              <Image boxSize="24px" m="10px" src={lupa} alt="lupaIcon" />
            </Button>
          </ModalFooter>
        </ModalContent>
      )}
    </Modal>
  );
}

export default ModalImage;
