import React, { Suspense, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import axios from "axios";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { TextureLoader, ShaderMaterial } from "three";
import { IoMdDownload } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { useImageContext } from "../../components/ImageContext/ImageProvider";

import fotoProcesada from "../../assets/examples/pantoSep.jpg";
import ModalBack from "../../components/ModalBack/ModalBack";
import ModalDownload from "../../components/ModalDownload/ModalDownload";

import dowloadSvg from "../../assets/svg/dowload.svg";
import back from "../../assets/svg/back.svg";
import change from "../../assets/svg/change.svg";
import input from "../../assets/svg/input.svg";
import figures from "../../assets/svg/figures.svg";
import { ChevronDownIcon } from "@chakra-ui/icons";

import LoadingSpinner from "../../components/Spinner/LoadingSpinner";
import logo2024 from "../../assets/img/common/home/Logo2024.png";

import LanguageDropDown from "../../components/LanguageDropdown/LanguageDropDown";
import Footer from "../../components/Footer/Footer";

import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Box,
  Text,
  Button,
  Image,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Switch,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";

const MyNegatoscopio = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    isOpen: isBackOpen,
    onOpen: onBackOpen,
    onClose: onBackClose,
  } = useDisclosure();

  const {
    isOpen: isDownloadOpen,
    onOpen: onDownloadOpen,
    onClose: onDownloadClose,
  } = useDisclosure();

  const [valueSepia, setValueSepia] = React.useState(0);
  const [valueGrises, setValueGrises] = React.useState(0);
  const [valueHue, setValueHue] = React.useState(0);
  const [valueInvertido, setValueInvertido] = React.useState(0);

  const [isInvert, setIsInvert] = useState(false);
  const [isDiag, setIsDiag] = useState(false);

  const [radioSup, setRadioSup] = React.useState(2.5);
  const [radioInf, setRadioInf] = React.useState(1.75);
  const [sliderValue, setSliderValue] = useState(0.5);
  const [opacity1, setOpacity1] = useState(1);
  const [opacity2, setOpacity2] = useState(0);

  const [isSliderLocked, setIsSliderLocked] = useState(true);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const { selectedImage, setSelectedImage, clearSelectedImage } =
    useImageContext();
  window.addEventListener("popstate", function (event) {
    clearSelectedImage();
    navigate("/");
  });

  window.addEventListener("load ", function (event) {
    clearSelectedImage();
    navigate("/");
  });

  // SLIDER
  const handleSliderChange = (newValue) => {
    setSliderValue(newValue);
    if (newValue < 0.5) {
      const adjustedValue = 2 + (0.5 - newValue) * 2;
      setRadioInf(adjustedValue);
    } else {
      const adjustedValue = 2 + (newValue - 0.5) * 2;
      setRadioSup(adjustedValue);
    }
  };

  // FILTERS
  const filters = {
    gray: () => setValueGrises(50),
    sepia: () => setValueSepia(50),
    hue: () => setValueHue(50),
    invert: () => setValueInvertido(100),
  };

  const applyFilter = (filterName) => {
    resetFilters();
    const filterFunction = filters[filterName];
    if (filterFunction) {
      filterFunction();
    }
  };

  const resetFilters = () => {
    setValueGrises(0);
    setValueSepia(0);
    setValueHue(0);
  };

  // SWITCHES
  const handleSwitchNegative = (event) => {
    setIsInvert(event.target.checked);
    if (event.target.checked) {
      applyFilter("invert");
    } else {
      setValueInvertido(0);
    }
  };

  const handleDiagnosis = (event) => {
    setIsDiag(event.target.checked);
  };

  // IMG
  const handleImageLoad = () => {
    setIsImageLoaded(true);
    setIsSliderLocked(false);
  };

  // CILINDER
  const newSelectedImages = [...selectedImage];
  let texture1 = new TextureLoader().load(newSelectedImages[0]);
  let texture2 = new TextureLoader().load(newSelectedImages[1]);

  const shaderMaterial = new ShaderMaterial({
    uniforms: {
      texture1: { value: isDiag ? texture2 : texture1 },
      opacity1: { value: opacity1 },
      opacity2: { value: opacity2 },
    },
    vertexShader: `
      varying vec2 vUv;
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
    fragmentShader: `
      uniform sampler2D texture1;
      uniform sampler2D texture2;
      uniform float opacity2; // Opacidad de la primera imagen
      uniform float opacity1; // Opacidad de la segunda imagen
      varying vec2 vUv;
      void main() {
        vec4 color1 = texture2D(texture1, vUv);
        vec4 color2 = texture2D(texture2, vUv);
        gl_FragColor = mix(color1, color2, opacity1 * opacity2); // Aplicar opacidad
      }
    `,
  });

  function Cilindro() {
    const [textures, setTextures] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const loader = new TextureLoader();

      const loadTexturesSync = async () => {
        const loadedTextures = [];
        for (const image of selectedImage) {
          const texture = await loader.loadAsync(image);
          loadedTextures.push(texture);
        }
        setTextures(loadedTextures);
        setLoading(false);
      };

      loadTexturesSync();
    }, []);

    return (
      <mesh scale={[1.05, 1.2, 1.05]}>
        <cylinderGeometry
          args={[radioSup, radioInf, 3, 100, 1, true, 4.4, 3.8]}
        />
        <shaderMaterial attach="material" args={[shaderMaterial]} />
      </mesh>
    );
  }

  return (
    <Box>
      <LanguageDropDown />
      <Box width="100vw" height="100vh" display="flex" flexDirection="column">
        <Box width="100vw" height="90vh" display="flex" flexDirection="row">
          <Box
            id="section1"
            flex="1"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection={"column"}
          >
            <Box id="logo" p={"10px"}>
              <Image boxSize="auto" width={"424px"} src={logo2024} alt="Icon" />
            </Box>
            <Box
              background={"white"}
              height={"320px"}
              width={"576px"}
              p={"10px"}
              mb={"26px"}
              overflowY="auto"
              borderRadius="32px 0px 0px 32px"
              borderColor={"azulClaroBackground"}
              borderWidth="2px"
            >
              <Text p={"8px"} fontSize={"24px"}>
                <Text
                  id="heading"
                  color="headingAzul"
                  fontFamily="primaryFont"
                  fontSize={"28px"}
                  fontStyle={"italic"}
                  mb={"12px"}
                >
                  {t("ImagingViewer.report_list_title")}
                </Text>
                <UnorderedList styleType="none">
                  {" "}
                  <ListItem>- {t("ImagingViewer.report_list_item_1")}</ListItem>
                  <ListItem>
                    - {t("ImagingViewer.report_list_item_2")}
                  </ListItem>
                  <ListItem>
                    - {t("ImagingViewer.report_list_item_3")}
                  </ListItem>
                  <ListItem>- {t("ImagingViewer.report_list_item_4")}</ListItem>
                  <ListItem>
                    - {t("ImagingViewer.report_list_item_5")}
                  </ListItem>
                </UnorderedList>
              </Text>
            </Box>
            <Box display={"flex"} flexDirection={"row"} gap={6} p={"10px"}>
              <Button
                _hover="none"
                background="azulClaroBackground"
                boxShadow={
                  "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)"
                }
                color="black"
                height="48px"
                width="260px"
                borderRadius="30px"
                fontSize="24px"
                gap={2}
                onClick={onBackOpen}
              >
                <Image boxSize="24px" src={back} alt="backIcon" />
                {t("ImagingViewer.button_back")}
                <ModalBack isOpen={isBackOpen} onClose={onBackClose} />
              </Button>
              <Button
                _hover="none"
                boxShadow={
                  "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)"
                }
                background="secondaryColor"
                color="black"
                height="48px"
                width="260px"
                borderRadius="30px"
                fontSize="24px"
                gap={2}
                onClick={onDownloadOpen}
              >
                {t("ImagingViewer.button_download")}
                <ModalDownload
                  isOpen={isDownloadOpen}
                  onClose={onDownloadClose}
                />
                <Image boxSize="24px" src={dowloadSvg} alt="dowloadIcon" />
              </Button>
            </Box>
          </Box>
          <Box
            id="section2"
            flex="1"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection={"column"}
            gap={20}
            fontWeight={500}
          >
            <Box id="imageControllers">
              <Image
                alt="not found"
                borderRadius="md"
                height="180px"
                width={"460px"}
                src={selectedImage[0]}
                onLoad={handleImageLoad}
              />
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
            >
              <Box id="controllers" display={"flex"} p={"10px"} pr={"80px"}>
                <Box
                  id="buttonsControllers"
                  display={"flex"}
                  flexDirection={"column"}
                >
                  <Box
                    gap={6}
                    display={"flex"}
                    flexDirection={"column"}
                    p={"10px"}
                    mb={"16px"}
                  >
                    <Button
                      _hover="none"
                      background="azulClaroBackground"
                      boxShadow={
                        "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)"
                      }
                      color="black"
                      height="48px"
                      width="260px"
                      borderRadius="30px"
                      fontSize="24px"
                      gap={2}
                      isLoading
                      spinner={false}
                    >
                      {t("ImagingViewer.controller.controller_change")}
                      <Image boxSize="24px" src={change} alt="changeIcon" />
                    </Button>
                    <Menu>
                      <MenuButton
                        as={Button}
                        _hover="none"
                        background="white"
                        boxShadow="0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)"
                        color="black"
                        height="48px"
                        width="260px"
                        borderRadius="30px"
                        fontSize="24px"
                        justifyContent="flex-start"
                        alignItems="center"
                        gap={2}
                        rightIcon={<ChevronDownIcon />}
                        leftIcon={
                          <Image boxSize="24px" src={input} alt="inputIcon" />
                        }
                      >
                          {t("ImagingViewer.controller.controller_colors")}
                      </MenuButton>
                      <MenuList height="48px" width="260px" fontSize={"24px"}>
                        <MenuItem onClick={() => resetFilters()}>
                          <Text
                            textAlign="center"
                            width="100%"
                            fontWeight={500}
                          >
                              {t("ImagingViewer.controller.controller_original")}
                          </Text>
                        </MenuItem>
                        <MenuItem onClick={() => applyFilter("gray")}>
                          <Text
                            textAlign="center"
                            width="100%"
                            fontWeight={500}
                          >
                              {t("ImagingViewer.controller.controller_gray")}
                          </Text>
                        </MenuItem>
                        <MenuItem onClick={() => applyFilter("sepia")}>
                          <Text
                            textAlign="center"
                            width="100%"
                            fontWeight={500}
                          >
                            {t("ImagingViewer.controller.controller_sep")}
                          </Text>
                        </MenuItem>
                        <MenuItem onClick={() => applyFilter("hue")}>
                          <Text
                            textAlign="center"
                            width="100%"
                            fontWeight={500}
                          >
                            {t("ImagingViewer.controller.controller_blue")}
                          </Text>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Box>
                  <Box display={"flex"} flexDirection={"row"} p={"10px"}>
                    <Box
                      id="labelsControlls"
                      display={"flex"}
                      flexDirection={"column"}
                      gap={"10px"}
                      fontSize={"24px"}
                      color={"black"}
                    >
                      <Text pb={2}>{t("ImagingViewer.controller.controller_inv")}</Text>
                      <Text>{t("ImagingViewer.controller.controller_visualization")}</Text>
                    </Box>
                    <Box
                      id="switchesControlls"
                      display={"flex"}
                      flexDirection={"column"}
                      gap={"10px"}
                      p={"10px"}
                    >
                      <Switch
                        id="negative"
                        size="lg"
                        pb={2}
                        onChange={handleSwitchNegative}
                      />
                      <Switch
                        id="diagnosis"
                        size="lg"
                        onChange={handleDiagnosis}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box id="slider" p="10px">
                <Box p="10px">
                  <Box
                    fontSize={"24px"}
                    display={"flex"}
                    gap={4}
                    alignItems="center"
                    mb={4}
                  >
                    <Image boxSize="24px" src={figures} alt="figuresIcon" />
                    {t("ImagingViewer.controller.controller_name")}
                  </Box>
                  <Box
                    height="200px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Slider
                      orientation="vertical"
                      minH="150px"
                      aria-label="Cylinder control"
                      defaultValue={0.5}
                      min={0}
                      max={1}
                      step={0.00001}
                      value={sliderValue}
                      onChange={(newValue) => handleSliderChange(newValue)}
                      isDisabled={!isImageLoaded}
                    >
                      <SliderTrack
                        bg={"azulClaroBackground"}
                        width="20px"
                        borderRadius="full"
                      >
                        <SliderFilledTrack bg={"#4276B4"} boxShadow={"6px"} />
                      </SliderTrack>
                      <SliderThumb
                        bg={"#4276B4"}
                        borderRadius="full"
                        width="28px"
                        h="24px"
                      />
                    </Slider>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            id="section3"
            flex="1"
            display="flex"
            flexDirection={"column"}
            justifyContent="center"
            alignItems="center"
            mt={"120px"}
            mr={"20px"}
          >
            {selectedImage.length > 0 && (
              <Box id="cilinder" h="64vh" w="600px">
                <Canvas
                  style={{
                    filter: `sepia(${valueSepia}%) grayscale(${valueGrises}%) invert(${valueInvertido}%) hue-rotate(${valueHue}deg)`,
                  }}
                >
                  <Suspense fallback={null}>
                    <ambientLight intensity={0.5} />
                    <spotLight
                      angle={0.15}
                      penumbra={1}
                      position={[10, 10, 10]}
                    />
                    <pointLight position={[-10, -10, -10]} />
                    <Cilindro
                      position={[0, 0, 0]}
                      radioInf={radioInf}
                      radioSup={radioSup}
                      rotation={[0, -1.5, 0]}
                    />
                  </Suspense>
                  <OrbitControls
                    maxAzimuthAngle={1.5}
                    maxPolarAngle={0}
                    minAzimuthAngle={-1.5}
                    minPolarAngle={1.6}
                    enableZoom={true}
                  />
                </Canvas>
              </Box>
            )}
            <Text
              fontSize="24px"
              color={"black"}
              textAlign={"center"}
              fontFamily={"secondaryFont"}
              mb={"110px"}
            >
              {t("ImagingViewer.caption_text")}{" "}
              <Text as="span" fontWeight="bold">
              {t("ImagingViewer.caption_text2")}
              </Text>{" "}
              {t("ImagingViewer.caption_text3")}{" "}
              <Text as="span" fontWeight="bold">
              {t("ImagingViewer.caption_text4")}
              </Text>{" "}
              {t("ImagingViewer.caption_text5")}
            </Text>
          </Box>
        </Box>
        <Box height={"10vh"}>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};

export default MyNegatoscopio;
