import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useImageContext } from "../../components/ImageContext/ImageProvider";
import { useTranslation } from "react-i18next";

function ModalBack({ isOpen, onClose }) {
  const { t } = useTranslation();
  // NAVIGATION
  const navigate = useNavigate();
  const { selectedImage, setSelectedImage, clearSelectedImage } =
    useImageContext();

  const goBack = () => {
    clearSelectedImage();
    navigate("/");
  };

  return (
    <Modal
      closeOnOverlayClick={true}
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent
        borderRadius={"100px"}
        maxH={"448px"}
        maxW={"874px"}
        p={"30px"}
        fontFamily="secondaryFont"
        fontSize={"24px"}
      >
        <ModalCloseButton />
        <ModalBody
          display="flex"
          justifyContent="center"
          mt={"10px"}
          fontWeight={"400"}
          fontSize={"24px"}
          mb={"10px"}
        >
          <Text>{t("ImagingViewer.button_volver_confirm")}</Text>
        </ModalBody>

        <ModalFooter
          display="flex"
          justifyContent="center"
          mt={"10px"}
          fontWeight={"400"}
          fontSize={"24px"}
          mb={"10px"}
        >
          <Button
            _hover="none"
            boxShadow={
              "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)"
            }
            background="languageDropDown"
            color="black"
            height="48px"
            width="260px"
            borderRadius="30px"
            fontSize="24px"
            mr={3}
            gap={2}
            onClick={onClose}
          >
            {t("ImagingViewer.button_cancel")}
          </Button>

          <Button
            background="azulClaroBackground"
            boxShadow={
              "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)"
            }
            color="black"
            height="48px"
            width="260px"
            borderRadius="30px"
            fontSize={"24px"}
            _hover={{ backgroundColor: "none" }}
            gap={2}
            onClick={goBack}
          >
            {t("ImagingViewer.button_back")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ModalBack;
