import { useImageContext } from "../components/ImageContext/ImageProvider";
import pantoejemplo2 from "../assets/examples/pantoejemplo2.jpg"

const useProcessImage = () => {
  const { setSelectedImage, selectedImage } = useImageContext();

  const processImage = async (selectedFile) => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("imagen", selectedFile);

      const requestOptions = {
        method: "POST",
        body: formData,
      };

      try {
        const response = await fetch(
          "https://api.intelligenceview.com/process_panto",
          requestOptions
        );
        const data = await response.json();

        if (response.ok) {
          const overlayImagesObj = data.data.img_overlay;
          const overlayImageUrls = Object.values(overlayImagesObj);
          setSelectedImage([...selectedImage, ...overlayImageUrls]);

          const inferences = data.data.inferencia;
          const pieces = data.data.piezas;
          const report = data.data.reporte;
        } else {
          console.error("Error en la respuesta de la API:", data);
        }
      } catch (error) {
        console.error("Error al procesar la solicitud:", error);
      }
    } else {
      console.error("No se ha seleccionado ningún archivo");
    }
  };

  return { processImage };
};

export default useProcessImage;
