import React from "react";
import { useState } from "react";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Icon,
  Text,
  Stack,
  Image,
} from "@chakra-ui/react";
import { FaArrowLeft, FaDownload, FaCheck } from "react-icons/fa";
import dowloadSvg from "../../assets/svg/dowload.svg";
import back from "../../assets/svg/back.svg";
import { saveAs } from "file-saver";
import example from "../../assets/pdf/example.pdf";
import { useTranslation } from "react-i18next";

function ModalDownload({ isOpen, onClose }) {
  const { t } = useTranslation();
  const [hasDownloaded, setHasDownloaded] = useState(false);
  const downloadDiag = () => {
    saveAs(example, "example.pdf");
    setHasDownloaded(true);
    setTimeout(() => {
      setHasDownloaded(false);
    }, 8000);
  };

  return (
    <Modal
      closeOnOverlayClick={true}
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent
        borderRadius={"100px"}
        maxH={"448px"}
        maxW={"874px"}
        p={"30px"}
        fontFamily="secondaryFont"
        fontSize={"24px"}
      >
        <ModalCloseButton />
        <ModalBody>
          {hasDownloaded ? (
            <>
              <ModalBody
                display="flex"
                flexDirection={"column"}
                justifyContent="center"
                alignContent={"center"}
                textAlign={"center"}
                mt={"10px"}
                fontWeight={"400"}
                fontSize={"24px"}
                mb={"10px"}
              >
                <Box display={"flex"} justifyContent={"center"} w={"full"}>
                  <Icon as={FaCheck} boxSize={8} color="blue.500" mb={4} />
                </Box>
                <Text>{t("ImagingViewer.button_download_success")}</Text>
              </ModalBody>
            </>
          ) : (
            <>
              <ModalBody
                display="flex"
                flexDirection={"column"}
                justifyContent="center"
                alignContent={"center"}
                textAlign={"center"}
                mt={"10px"}
                fontWeight={"400"}
                fontSize={"24px"}
                mb={"10px"}
              >
                <Box display={"flex"} justifyContent={"center"} w={"full"}>
                  <Icon as={FaDownload} boxSize={8} color="blue.500" mb={4} />
                </Box>
                <Text>{t("ImagingViewer.button_download_confirm")}</Text>
              </ModalBody>
            </>
          )}
        </ModalBody>

        <ModalFooter justifyContent="center">
          {hasDownloaded ? (
            <Button
              _hover="none"
              background="azulClaroBackground"
              boxShadow={
                "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)"
              }
              color="black"
              height="48px"
              width="260px"
              borderRadius="30px"
              fontSize="24px"
              gap={2}
              onClick={onClose}
            >
              <Image boxSize="24px" src={back} alt="backIcon" />
              {t("ImagingViewer.button_back")}
            </Button>
          ) : (
            <Stack direction="row" spacing={4}>
              <Button
                _hover="none"
                background="azulClaroBackground"
                boxShadow={
                  "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)"
                }
                color="black"
                height="48px"
                width="260px"
                borderRadius="30px"
                fontSize="24px"
                gap={2}
                onClick={onClose}
              >
                <Image boxSize="24px" src={back} alt="backIcon" />
                {t("ImagingViewer.button_back")}
              </Button>

              <Button
                _hover="none"
                boxShadow={
                  "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)"
                }
                background="secondaryColor"
                color="black"
                height="48px"
                width="260px"
                borderRadius="30px"
                fontSize="24px"
                gap={2}
                onClick={() => downloadDiag()}
              >
                {t("ImagingViewer.button_download")}
                <Image boxSize="24px" src={dowloadSvg} alt="dowloadIcon" />
              </Button>
            </Stack>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ModalDownload;
