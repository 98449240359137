import React, { createContext, useState, useContext } from "react";

const ImageContext = createContext();

export const ImageProvider = ({ children }) => {
  const [selectedImage, setSelectedImage] = useState([]);

  const clearSelectedImage = () => {
    setSelectedImage([]);
  };

  return (
    <ImageContext.Provider value={{ selectedImage, setSelectedImage, clearSelectedImage }}>
      {children}
    </ImageContext.Provider>
  );
};

export const useImageContext = () => {
  return useContext(ImageContext);
};
