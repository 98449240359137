import { useTranslation } from "react-i18next";
import {
  Box,
  Flex,
  Image,
  ListItem,
  Text,
  UnorderedList,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import LanguageDropDown from "../../components/LanguageDropdown/LanguageDropDown";
import ModalImage from "../../components/ModalImage/ModalImage";

import imageHome from "../../assets/img/common/home/Home.png";
import logo2024 from "../../assets/img/common/home/Logo2024.png";
import tick from "../../assets/svg/tick.svg";
import lupa from "../../assets/svg/lupa.svg";

import Footer from "../../components/Footer/Footer";

export default function Home() {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex flexDirection="column">
      <Flex
        fontSize="32px"
        fontFamily="primaryFont"
        justifyContent="center"
        alignItems="center"
        flexDirection="row"
        height={"90vh"}
      >
        <LanguageDropDown />
        <Box id="first_box" pr={"20px"}>
          <Box
            display="flex"
            mb={"30px"}
            alignItems="center"
            justifyContent="center"
          >
            <Box display="flex" justifyContent="center" pb={"6"}>
              <Image boxSize="auto" width={"424px"} src={logo2024} alt="Icon" />
            </Box>
          </Box>
          <Box
            color="#000"
            textAlign="justify"
            fontFamily="secondaryFont"
            fontSize="32px"
            fontWeight="400"
            lineHeight="48px"
            display={"flex"}
            justifyContent="center"
            alignContent={"center"}
            height="380px"
            py={"30px"}
          >
            <UnorderedList listStyleType="none" margin="0">
              <ListItem display="flex" alignItems="center" gap={2}>
                <Image src={tick} alt="tick" width="28px" height="28px" />
                {t("Header.about_1_bullet")}
              </ListItem>
              <ListItem display="flex" alignItems="center" gap={2}>
                <Image src={tick} alt="tick" width="28px" height="28px" />
                {t("Header.about_1_bullet1")}
              </ListItem>
              <ListItem display="flex" alignItems="center" gap={2}>
                <Image src={tick} alt="tick" width="28px" height="28px" />
                {t("Header.about_1_bullet2")}
              </ListItem>
            </UnorderedList>
          </Box>
        </Box>
        <Box mr={"80px"} ml={"80px"} p={"60px"} width={"480px"} id="second_box">
          <Box
            color="#000"
            fontFamily="secondaryFont"
            fontSize="24px"
            fontWeight="400"
            lineHeight="48px"
            width="480px"
            height="530px"
            justifyContent={"center"}
            alignContent={"center"}
          >
            <Box mb={"130px"}>
              <Text
                color="headingAzul"
                fontFamily="primaryFont"
                fontSize={"28px"}
                fontStyle={"italic"}
                mb={"12px"}
              >
                {t("Header.about_2_title1")}
              </Text>
              <UnorderedList
                marginBottom="40px"
                listStyleType="none"
                margin="0"
              >
                <ListItem display={"flex"} alignItems={"center"} gap={2}>
                  <Image src={tick} alt="tick" width="28px" height="28px" />
                  {t("Header.about_2_title1_bullet")}
                </ListItem>
                <ListItem display={"flex"} alignItems={"center"} gap={2}>
                  <Image src={tick} alt="tick" width="28px" height="28px" />
                  {t("Header.about_2_title1_bullet1")}
                </ListItem>
                <ListItem display={"flex"} alignItems={"center"} gap={2}>
                  <Image src={tick} alt="tick" width="28px" height="28px" />
                  {t("Header.about_2_title1_bullet2")}
                </ListItem>
              </UnorderedList>
            </Box>
            <Box>
              <Text
                color="headingAzul"
                fontFamily="primaryFont"
                fontSize={"28px"}
                fontStyle={"italic"}
                mb={"12px"}
              >
                {t("Header.about_2_title2")}
              </Text>
              <UnorderedList listStyleType="none" margin="0">
                <ListItem display={"flex"} alignItems={"center"} gap={2}>
                  <Image src={tick} alt="tick" width="28px" height="28px" />
                  {t("Header.about_2_title2_bullet")}
                </ListItem>
                <ListItem display={"flex"} alignItems={"center"} gap={2}>
                  <Image src={tick} alt="tick" width="28px" height="28px" />
                  {t("Header.about_2_title2_bullet1")}
                </ListItem>
              </UnorderedList>
            </Box>
          </Box>
        </Box>
        <Box pt={10} pl={10} id="third_box">
          <Box>
            <Image src={imageHome} alt="panto" borderRadius="md" height={600} />
            <Box display="flex" justifyContent="right" p={"50px"}>
              <Button
                onClick={onOpen}
                fontSize={"24px"}
                borderRadius={"100px"}
                boxShadow={
                  "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)"
                }
                fontFamily={"secondaryFont"}
                color={"black"}
                backgroundColor={"secondaryColor"}
                _hover={{ backgroundColor: "none" }}
                p={"14px 10px 14px 10px"}
                width={276}
                gap={2}
              >
                <Text>{t("Header.button_analize")}</Text>
                <Image src={lupa} alt="lupa" width={"20px"} height={"20px"} />
              </Button>
              <ModalImage isOpen={isOpen} onClose={onClose} />
            </Box>
          </Box>
        </Box>
      </Flex>
      <Box height={"10vh"}>
        <Footer />
      </Box>
    </Flex>
  );
}
